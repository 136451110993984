import React from 'react';
import Footer from './Footer/Footer';
import Navbar from './Navbar/Navbar';

const Layout = ({ id, children }) => {
  return (
    <main>
      <Navbar />
      <section id={id} className="layout container mt-52">
        {children}
      </section>
      <Footer />
    </main>
  );
};

export default Layout;
