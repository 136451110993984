import React, { useEffect, useState } from 'react';
import * as is from 'is_js';
import { Accordion, Card, Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import Layout from '../../components/Layout';
import { headData, pricesData } from '../../mock/data';

export default function PriceList() {
  const { lang, title } = headData;
  const [activeTitles, setActiveTitles] = useState([]);
  const [prices, setPrices] = useState(pricesData);

  const filterPrices = (e) => {
    e.preventDefault();

    const searchValue = e.currentTarget.value;

    setPrices(
      pricesData.map((price) => {
        switch (price.id) {
          case 'ultrasound':
            return {
              ...price,
              values: filterPricesArray('ultrasound', searchValue),
            };
          case 'doppler':
            return {
              ...price,
              values: filterPricesArray('doppler', searchValue),
            };
          case 'extras':
            return {
              ...price,
              values: filterPricesArray('extras', searchValue),
            };

          default:
            break;
        }
      })
    );
  };

  const filterPricesArray = (key, searchValue) => {
    const prices = getPrice(key);
    const filterResult = prices.filter(
      (item) => item.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
    );

    return filterResult;
  };

  const getPrice = (key) => {
    return pricesData.filter((price) => {
      return price.id === key;
    })[0].values;
  };

  const handleTitleClick = (clickedTitleId) => {
    const clickedTitle = activeTitles.filter((title) => title === clickedTitleId)[0];
    let newActiveTitles = activeTitles.map((clickedTitleId) => clickedTitleId);

    if (clickedTitle !== undefined) {
      newActiveTitles = newActiveTitles.filter((title) => {
        return clickedTitleId !== title;
      });

      setActiveTitles(newActiveTitles);
      return;
    }

    newActiveTitles.push(clickedTitleId);
    setActiveTitles(newActiveTitles);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Cjenik | {title}</title>
        <html lang={lang || 'en'} />
        <meta name="description" content="Cjenik za ambulantu ULTRASANO dr. Saghir" />
      </Helmet>
      <Layout id="price">
        {/* <section className="price-search">
          <Form>
            <Form.Group controlId="form-search">
              <span className="search-icon">
                <i className="fa fa-search"></i>
              </span>
              <Form.Control onChange={filterPrices} type="text" placeholder="Pretraži" />
            </Form.Group>
          </Form>
        </section> */}
        <section className="container price-list">
          {prices.map((price, index) => {
            return (
              <React.Fragment key={index}>
                <h2>{price.title}</h2>
                {price.values.map((value, index) => {
                  const selectedTitle = activeTitles.filter(
                    (clickedTitleId) => clickedTitleId === value.name
                  )[0];
                  const closeButtonClass = selectedTitle ? 'active' : '';
                  return (
                    <Accordion key={index}>
                      <Card>
                        <Accordion.Toggle
                          onClick={() => handleTitleClick(value.name)}
                          className="card-title"
                          as="div"
                          eventKey="0"
                        >
                          <span className={closeButtonClass}>
                            <i className="fa fa-plus"></i>
                          </span>
                          <div className="price-title">
                            <h3>{value.name}</h3>
                            {is.array(value.price) ? (
                              <div className="price-title-multiple">
                                {value.price.map((item, index) => (
                                  <div key={index} className="price-description-longer">
                                    <span>{item.name}</span>
                                    <span>{item.value} EUR</span>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <div className="price-description">
                                <span>Cijena</span>
                                <span>{value.price} EUR</span>
                              </div>
                            )}
                          </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body className="card-body">
                            <p>{value.description}</p>
                            {/* {is.array(value.price) ? (
                              <>
                                {value.price.map((item, index) => (
                                  <div key={index} className="price-description">
                                    <span>Cijena pregleda: {item.name}</span>
                                    <span>{item.value} EUR</span>
                                  </div>
                                ))}
                              </>
                            ) : (
                              <div className="price-description">
                                <span>Cijena pregleda</span>
                                <span>{value.price} EUR</span>
                              </div>
                            )} */}
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  );
                })}
              </React.Fragment>
            );
          })}
        </section>
      </Layout>
    </>
  );
}
